import request from '@/utils/request2'
import Dao from '@/utils/dao'

function returnHeader() {
  return {
    'x-5a-temp-token': Dao.get('claimToken')
  }
}

export function getDetail(query) {
  return request({
    url: '/pro/claim/temp/form/single/detail',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function saveForDraft(query) {
  return request({
    url: '/pro/claim/temp/form/save',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function saveForSubmit(query) {
  return request({
    url: '/pro/claim/temp/form/submit',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

// 理赔2期，report
export function claimCategory(query) {
  return request({
    url: '/pro/claim/temp/product/category',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimCodetable(query) {
  return request({
    url: '/pro/claim/temp/codetable/list',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimCustomerSubmit(query) {
  return request({
    url: '/pro/claim/temp/report/customer/submit',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimPolicySubmit(query) {
  return request({
    url: '/pro/claim/report/policy/submit',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimQueryReportPolicyList(query) {
  return request({
    url: '/pro/claim/temp/policy/query/by/insured/object',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimReportPolicyQueryByCode(query) {
  return request({
    url: '/pro/claim/temp/policy/query/by/code',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}
